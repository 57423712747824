<template>
    <div>
        <p v-if="scannedItems.length < 1" class="text-center fs--1 text-secondary my-2">Nothing scanned yet</p>
        <Product v-for="product in productVariants"
                 :key="product.productVariantId"
                 :product="product"
                 :completed-quantity="product.quantityScanned"
                 class="mb-2" />
    </div>
</template>

<script>
    import Product from './Product.vue';

    export default {
        components: { Product },
        props: {
            scannedItems: { type: Array, required: true },
        },
        computed: {
            productVariants: vm => {
                const productVariants = new Map();

                vm.scannedItems.forEach((item) => {
                    const productVariantId = item.productVariant.bigcommerce_product_variant_id;
                    if (productVariants.has(productVariantId)) {
                        productVariants.get(productVariantId).quantityScanned += 1;
                    } else {
                        productVariants.set(productVariantId, {
                            productVariantId: item.productVariant.bigcommerce_product_variant_id,
                            name: item.productVariant.product_name,
                            sku: item.productVariant.name,
                            imageUrl: item.productVariant.image_url,
                            inStock: item.productVariant.is_in_stock,
                            quantityShipped: item.productVariant.quantity_shipped,
                            quantityScanned: 1,
                        });
                    }
                });

                return Array.from(productVariants.values());
            },
        },
    };
</script>
