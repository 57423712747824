<template>
    <div class="border rounded">
        <div class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap p-2 bg-light rounded-top border-bottom">
            <fieldset class="d-flex align-items-center order-0">
                <label class="mx-2 my-0" v-tooltip title="Weight">
                    <i class="fas fa-weight-hanging" />
                </label>
                <div class="input-group input-group-sm">
                    <input type="number"
                           required
                           min="0.1"
                           max="150.00"
                           step="0.1"
                           class="form-control"
                           style="max-width: 7em;"
                           v-model.number.lazy="weight"
                           @focus="$event.target.value = ''">
                    <div class="input-group-append">
                        <span class="input-group-text px-2">lbs</span>
                    </div>
                </div>
            </fieldset>
            <fieldset class="d-flex align-items-center mt-2 mt-sm-0 ml-sm-1 order-1 order-sm-0">
                <label class="mx-2 my-0" v-tooltip title="Dimensions">
                    <i class="fas fa-ruler-combined" />
                </label>
                <div class="input-group input-group-sm">
                    <input type="number"
                           required
                           min="1"
                           max="200"
                           step="1"
                           class="form-control"
                           style="max-width: 6em;"
                           v-model.number.lazy="length"
                           @focus="$event.target.value = ''">
                    <input type="number"
                           required
                           min="1"
                           max="200"
                           step="1"
                           class="form-control"
                           style="max-width: 6em;"
                           v-model.number.lazy="width"
                           @focus="$event.target.value = ''">
                    <input type="number"
                           required
                           min="1"
                           max="200"
                           step="1"
                           class="form-control"
                           style="max-width: 6em;"
                           v-model.number.lazy="height"
                           @focus="$event.target.value = ''">
                    <div class="input-group-append">
                        <span class="input-group-text px-2">in</span>
                        <template v-if="dimensionsPresets && dimensionsPresets.length > 0">
                            <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" />
                            <div class="dropdown-menu dropdown-menu-right py-2">
                                <button v-for="preset in dimensionsPresets"
                                        :key="preset._id"
                                        type="button"
                                        class="dropdown-item"
                                        @click="applyDimensionsPreset(preset)">
                                    {{ preset.display_name }}
                                </button>
                            </div>
                        </template>
                    </div>
                </div>
            </fieldset>
            <button type="button"
                    class="btn btn-close my-n1 px-2 order-0"
                    v-tooltip="{ placement: 'left' }"
                    :disabled="!canBeRemoved"
                    title="Remove this package"
                    @click="$emit('remove-package')">
                <i class="fa fa-times" />
            </button>
        </div>
        <div class="media">
            <GS1ScanField :scan-needed="pkg.items.length < 1"
                          @scan-parse="handleScannedItem"
                          @scan-error="$alerts.danger('Invalid Scan', 'The scanned barcode could not be parsed.')"
                          class="p-sm-3 p-2 align-self-stretch" />
            <div class="media-body">
                <p v-if="!pkg.items.length && !pendingItemsCount" class="my-5 text-center fs--1 text-secondary">
                    Please scan an item into this package
                </p>
                <PackageItem v-for="(item, index) in pkg.items"
                             :key="item._id"
                             :item="item"
                             @remove-item="$emit('remove-item', index)"
                             class="my-2 mr-2" />
                <div v-if="pendingItemsCount > 0" class="my-4 text-center">
                    <div class="spinner-border spinner-border-sm text-secondary" role="status" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { formatDecimal } from '../../filters';
    import { getProductVariants } from '../../services/ProductService';
    import GS1ScanField from '../../components/GS1ScanField.vue';
    import PackageItem from './PackageItem.vue';

    export default {
        components: { GS1ScanField, PackageItem },
        props: {
            pkg: { type: Object, required: true }, // Note: 'package' is a reserved word
            dimensionsPresets: { type: Array, default: null },
            canBeRemoved: { type: Boolean },
        },
        data: () => ({
            pendingItemsCount: 0,
        }),
        computed: {
            weight: {
                get() {
                    return formatDecimal(this.pkg.weight, 1);
                },
                set(newVal) {
                    this.$emit('set-weight', +newVal.toFixed(1));
                },
            },
            length: {
                get() {
                    return formatDecimal(this.pkg.length, 1);
                },
                set(newVal) {
                    this.$emit('set-length', +newVal.toFixed(1));
                },
            },
            width: {
                get() {
                    return formatDecimal(this.pkg.width, 1);
                },
                set(newVal) {
                    this.$emit('set-width', +newVal.toFixed(1));
                },
            },
            height: {
                get() {
                    return formatDecimal(this.pkg.height, 1);
                },
                set(newVal) {
                    this.$emit('set-height', +newVal.toFixed(1));
                },
            },
        },
        methods: {
            applyDimensionsPreset(preset) {
                this.length = preset.length;
                this.width = preset.width;
                this.height = preset.height;
            },
            async handleScannedItem(gs1ScanData) {
                this.pendingItemsCount += 1;

                class ScanError extends Error {}

                try {
                    const elements = gs1ScanData.elements;

                    const gtin = elements['01'];
                    if (!gtin) {
                        throw new ScanError('The scanned item does not have a valid GTIN.');
                    }

                    // Call the API to get the ProductVariant information for this GTIN
                    const { data } = await getProductVariants({ gtin });
                    const productVariant = data[0];
                    if (!productVariant) {
                        throw new ScanError('No SKU was found for the scanned item\'s GTIN.');
                    }

                    this.$emit('add-item', {
                        gtin,
                        barcodeData: gs1ScanData.elements,
                        productVariant,
                    });
                } catch (error) {
                    if (error instanceof ScanError) {
                        this.$alerts.danger('Invalid Scan', error.message);
                    } else {
                        this.$alerts.danger('Scan Error', 'An error occurred while trying to read the scanned item.');
                    }
                } finally {
                    this.pendingItemsCount -= 1;
                }
            },
        },
    };
</script>
