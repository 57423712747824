<template>
    <div class="media">
        <img :src="product.imageUrl || fallbackImageUrl"
             :alt="product.name"
             width="50"
             class="mr-2">
        <div class="media-body">
            <div class="line-height-1">
                <strong>{{ product.name }}</strong> <small>{{ product.sku }}</small>
            </div>
            <div class="d-flex align-items-baseline mb-1">
                <span class="fs--2">
                    {{ product.inStock ? 'In Stock' : 'Out of Stock' }}
                </span>
                <span class="ml-auto text-nowrap fs--1">
                    <template v-if="orderedQuantity">
                        {{ completedQuantity }}&thinsp;/&thinsp;{{ orderedQuantity }}
                    </template>
                    <template v-else>
                        &times;{{ completedQuantity }}
                    </template>
                </span>
            </div>
            <ProgressBar v-if="orderedQuantity" :amount="completedQuantity" :total="orderedQuantity" />
        </div>
    </div>
</template>

<script>
    import ProgressBar from '../../components/ProgressBar.vue';

    export default {
        components: { ProgressBar },
        props: {
            product: { type: Object, required: true },
            completedQuantity: { type: Number, required: true },
            orderedQuantity: { type: Number, default: 0 },
        },
        created() {
            // Non-reactive constants for the template
            this.fallbackImageUrl = 'https://medshift-cdn.nyc3.cdn.digitaloceanspaces.com/default-placeholder.png';
        },
    };
</script>
