<template>
    <div class="mb-n3">
        <div v-for="(warehouse, index) in warehouses" :key="warehouse._id" class="mb-3">
            <div class="custom-control custom-radio radio-select">
                <input type="radio"
                       :value="warehouse"
                       v-model="valueProxy"
                       class="custom-control-input"
                       :id="`${id}-warehouse-${index}`"
                       :name="`${id}-warehouse`"
                       required>
                <label :for="`${id}-warehouse-${index}`"
                       class="custom-control-label font-weight-bold d-block">
                    {{ warehouse.name }}
                    <span class="radio-select-content">
                        {{ warehouse.address.address_line_1 }}
                        <template v-if="warehouse.address.address_line_2">
                            <br>{{ warehouse.address.address_line_2 }}
                        </template>
                        <br>{{ warehouse.address.city }},
                        {{ warehouse.address.state_province_code }}
                        {{ warehouse.address.postal_code }}
                        {{ warehouse.address.country_iso_code }}
                    </span>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            id: { type: String, default: '' },
            value: { type: null, required: true },
            warehouses: { type: Array, required: true },
        },
        computed: {
            valueProxy: {
                get() {
                    return this.value;
                },
                set(newVal) {
                    this.$emit('input', newVal);
                },
            },
        },
    };
</script>
