<template>
    <div>
        <transition mode="out-in">
            <span class="mr-1 d-inline-block" :key="iconClass">
                <i :class="iconClass" />
            </span>
        </transition>
        <slot />
    </div>
</template>

<script>
    export default {
        props: {
            isComplete: { type: Boolean },
        },
        computed: {
            iconClass: vm => vm.isComplete ? 'far fa-check-circle text-success' : 'far fa-times-circle text-secondary',
        },
    };
</script>

<style scoped>
    .v-enter-active {
        transition: transform .1s ease-out;
    }

    .v-leave-active {
        transition: transform .1s ease-in;
    }

    .v-enter, .v-leave-to {
        transform: scale(0);
    }
</style>
