<template>
    <LoadingSpinner v-if="pageIsLoading" class="vh-50" />
    <LoadingError v-else-if="pageLoadError" class="vh-50" />
    <div v-else>
        <div v-if="isForOrder" class="card bg-light mb-4">
            <div class="card-body p-3">
                <p class="fs--1 mb-0">
                    You are currently fulfilling
                    <router-link :to="{ name: 'order-details', params: { id: order.id }}" target="_blank">
                        <strong>Order #{{ order.bigcommerce_order_id }}</strong>
                    </router-link>
                </p>
            </div>
        </div>
        <form class="row" ref="addressesForm">
            <div class="col-lg-6 mb-4">
                <div class="card h-100">
                    <div class="card-header bg-light">
                        <h5 class="mb-0">Shipping To</h5>
                    </div>
                    <div class="card-body">
                        <ShippingAddress v-if="isForOrder" :address="order.shipping_address" />
                        <QuickShipAddressFieldset v-else v-model="customAddress" id="to-address" />
                    </div>
                </div>
            </div>
            <div class="col-lg-6 mb-4">
                <fieldset class="card h-100" :disabled="pageIsDisabled">
                    <div class="card-header bg-light">
                        <h5 class="mb-0">Shipping From</h5>
                    </div>
                    <div class="card-body">
                        <WarehouseSelection v-model="selectedWarehouse" :warehouses="warehouses" id="warehouses" />
                    </div>
                </fieldset>
            </div>
        </form>
        <div class="row">
            <div class="col-lg-4 mb-4">
                <div class="card">
                    <div class="card-header bg-light">
                        <h5 class="mb-0">Items</h5>
                    </div>
                    <div class="card-body">
                        <OrderProductsList v-if="isForOrder"
                                           :bc-products="order.bc_data.products"
                                           :order-product-variants="order.product_variants"
                                           :scanned-items="scannedItems" />
                        <QuickShipProductsList v-else :scanned-items="scannedItems" />
                    </div>
                </div>
            </div>
            <div class="col-lg-8 mb-4">
                <form class="card" ref="packagesForm">
                    <div class="card-header bg-light d-flex align-items-center btn-reveal-trigger">
                        <h5 class="mb-0">Packages</h5>
                        <button type="button"
                                class="btn btn-sm btn-link btn-reveal text-600 ml-auto my-n1"
                                title="Add a package"
                                v-tooltip="{ placement: 'left' }"
                                @click="addPackage">
                            <i class="fas fa-plus" />
                        </button>
                    </div>
                    <fieldset class="card-body mb-n3" :disabled="pageIsDisabled">
                        <Package v-for="(pkg, index) in packages"
                                 :key="pkg._id"
                                 :pkg="pkg"
                                 :dimensions-presets="packageDimensionsPresets"
                                 :can-be-removed="packages.length > 1"
                                 @remove-package="askToRemovePackageAt(index)"
                                 @set-weight="pkg.weight = $event"
                                 @set-length="pkg.length = $event"
                                 @set-width="pkg.width = $event"
                                 @set-height="pkg.height = $event"
                                 @add-item="pkg.items.push($event)"
                                 @remove-item="pkg.items.splice($event, 1)"
                                 class="mb-3" />
                    </fieldset>
                </form>
            </div>
        </div>
        <div class="card mb-4">
            <div class="card-header bg-light">
                <h5 class="mb-0">Shipping Options</h5>
            </div>
            <div class="card-body row">
                <form class="col-md-4 mb-md-0 mb-3" ref="shippingCarrierForm">
                    <fieldset :disabled="pageIsDisabled">
                        <p v-if="isForOrder" class="fs--1">
                            Customer selected:
                            <strong>{{ order.bc_data.shipping_addresses[0].shipping_method }}</strong>
                        </p>
                        <h6>Shipping Carrier</h6>
                        <AdminCarrierSelection v-model="selectedAdminCarrier"
                                               :carriers="adminCarriers"
                                               id="adminCarriers" />
                        <button type="button"
                                class="btn btn-sm mt-2 w-100"
                                :class="[shippingRates ? 'btn-outline-primary' : 'btn-primary']"
                                @click="getShippingRates">
                            <span v-if="isGettingRates" class="spinner-border spinner-border-sm mr-1" aria-hidden="true" />
                            Get Shipping Rates
                        </button>
                    </fieldset>
                </form>
                <form class="col-md-8" ref="shippingRatesForm">
                    <fieldset v-if="shippingRates" :disabled="pageIsDisabled">
                        <RatesSelection v-model="selectedShippingRate" :rates="shippingRates" id="shippingRates" />
                        <div class="alert alert-info fs--1 mt-3 mb-0">
                            Changing addresses, packages, or shipping carrier will clear these rates
                        </div>
                    </fieldset>
                    <div v-else class="text-center text-600 my-5">
                        <i class="fas fa-shipping-fast fs-2" />
                        <p class="fs--1 mt-2">Shipping rates will appear here</p>
                    </div>
                </form>
            </div>
        </div>
        <div class="card bg-light mb-4">
            <div class="card-body d-md-flex align-items-center justify-content-between p-3">
                <div class="d-sm-flex mb-md-0 mb-2">
                    <CompletionBadge :is-complete="addressesCompleted" class="mx-2">Shipping Addresses</CompletionBadge>
                    <CompletionBadge :is-complete="packagesCompleted" class="mx-2">Packages</CompletionBadge>
                    <CompletionBadge :is-complete="shippingCompleted" class="mx-2">Shipping Rate</CompletionBadge>
                </div>
                <div class="text-sm-right">
                    <button class="btn btn-sm btn-falcon-primary"
                            :disabled="!totalCompleted || pageIsDisabled"
                            @click="createShipment">
                        <span v-if="isCreatingShipment" class="spinner-border spinner-border-sm mr-1" aria-hidden="true" />
                        <i v-else class="fas fa-dolly" />
                        Create Shipment
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { createShipment, getRates } from '../../services/ShipmentService';
    import { addressToShipEngineFormat } from '../../util';
    import AdminCarrierSelection from './AdminCarrierSelection.vue';
    import CompletionBadge from './CompletionBadge.vue';
    import { getAdminCarriers } from '../../services/CarrierService';
    import { getOrder } from '../../services/OrderService';
    import { getPackageDimensionsPresets } from '../../services/PackageDimensionsPresetService';
    import LoadingError from '../../components/LoadingError.vue';
    import LoadingSpinner from '../../components/LoadingSpinner.vue';
    import OrderProductsList from './OrderProductsList.vue';
    import Package from './Package.vue';
    import QuickShipAddressFieldset from './QuickShipAddressFieldset.vue';
    import QuickShipProductsList from './QuickShipProductsList.vue';
    import RatesSelection from './RatesSelection.vue';
    import ShippingAddress from './ShippingAddress.vue';
    import WarehouseSelection from './WarehouseSelection.vue';

    const makeEmptyPackage = () => ({
        weight: 0,
        length: 0,
        width: 0,
        height: 0,
        items: [],
    });

    export default {
        components: { RatesSelection, ShippingAddress, WarehouseSelection, LoadingError, AdminCarrierSelection,
                      CompletionBadge, LoadingSpinner, Package, OrderProductsList, QuickShipProductsList,
                      QuickShipAddressFieldset },
        data: () => ({
            pageIsLoading: true,
            pageLoadError: false,
            isGettingRates: false,
            isCreatingShipment: false,

            order: null,
            warehouses: null,
            adminCarriers: null,
            packageDimensionsPresets: null,
            shippingRates: null,

            selectedWarehouse: null,
            selectedAdminCarrier: null,
            selectedShippingRate: null,
            packages: [makeEmptyPackage()],

            customAddress: {
                address_line_1: '',
                address_line_2: '',
                city: '',
                state_province_code: '',
                country_iso_code: 'US',
                postal_code: '',
                company_name: '',
                phone: '',
            },
        }),
        computed: {
            /** If the shipment is for an order, or for quick ship */
            isForOrder: vm => !!vm.$route.query['order-id'],
            /** If `true`, all inputs on the page should be given the `disabled` attribute */
            pageIsDisabled: vm => vm.isGettingRates || vm.isCreatingShipment,
            /** All items the user has scanned into this shipment */
            scannedItems: vm => vm.packages.flatMap(pkg => pkg.items),
            /** Validation status of shipping addresses */
            addressesCompleted: vm => !!vm.selectedWarehouse,
            /** Validation status of packages */
            packagesCompleted: vm => vm.packages.length > 0 &&
                vm.packages.every(pkg =>
                    pkg.items.length > 0 &&
                    pkg.weight >= 0.1 && pkg.weight < 150 &&
                    pkg.length >= 1 && pkg.length <= 200 &&
                    pkg.width >= 1 && pkg.width <= 200 &&
                    pkg.height >= 1 && pkg.height <= 200,
                ),
            /** Validation status of shipping rates */
            shippingCompleted: vm => !!vm.selectedAdminCarrier && !!vm.selectedShippingRate,
            /** Validation status of the entire page */
            totalCompleted: vm => vm.addressesCompleted && vm.packagesCompleted && vm.shippingCompleted,
        },
        watch: {
            // Clear shipping rates if they are invalidated
            'customAddress': { handler: 'clearShippingRates', deep: true },
            'selectedWarehouse': 'clearShippingRates',
            'selectedAdminCarrier': 'clearShippingRates',
            'packages': { handler: 'clearShippingRates', deep: true }, // TODO: Ignore changes to items
        },
        async mounted() {
            try {
                await Promise.all([
                    this.isForOrder && this.loadOrder(),
                    this.loadWarehouses(),
                    this.loadAdminCarriers(),
                    this.$auth.hasPermissions('read:package_dimensions') && this.loadPackageDimensionsPresets(),
                ]);
            } catch (error) {
                this.pageLoadError = true;
            } finally {
                this.pageIsLoading = false;
            }
        },
        methods: {
            async loadOrder() {
                // The previous page can pass us an already fetched order object
                if (this.$route.params._order) {
                    this.order = this.$route.params._order;
                    return;
                }

                const orderId = this.$route.query['order-id'];
                this.order = (await getOrder(orderId)).data;
            },
            async loadWarehouses() { // eslint-disable-line require-await
                // TODO: update to support loading warehouses from the API
                this.warehouses = [
                    {
                        name: 'Apyx Warehouse',
                        address: {
                            'address_line_1': '5115 ULMERTON RD',
                            'address_line_2': '',
                            'city': 'CLEARWATER',
                            'state_province_code': 'FL',
                            'country_iso_code': 'US',
                            'postal_code': '33760-4004',
                            'company_name': 'Apyx Medical Corporation',
                            'phone': '1-800-537-2790', // TODO
                        },
                    },
                ];

                // If there's only one warehouse, preselect it
                if (this.warehouses.length === 1) {
                    this.selectedWarehouse = this.warehouses[0];
                }
            },
            async loadAdminCarriers() {
                this.adminCarriers = (await getAdminCarriers()).data;

                // If there's only one admin carrier, preselect it
                if (this.adminCarriers.length === 1) {
                    this.selectedAdminCarrier = this.adminCarriers[0];
                }
            },
            async loadPackageDimensionsPresets() {
                this.packageDimensionsPresets = (await getPackageDimensionsPresets()).data;
            },
            addPackage() {
                this.packages.push(makeEmptyPackage());
                document.activeElement.blur(); // Clear focus on the button
            },
            askToRemovePackageAt(packageIndex) {
                if (this.packages[packageIndex].items.length > 0) {
                    const message = 'Removing this package will also remove its items. Are you sure?';
                    if (!confirm(message)) { return; }
                }

                this.packages.splice(packageIndex, 1);
            },
            async getShippingRates() {
                if (!this.runFormValidation(true, true, true, false)) {
                    return;
                }

                this.isGettingRates = true;

                // TODO: The API should eventually be updated to only accept API-formatted addresses
                const shipFrom = addressToShipEngineFormat({
                    name: this.selectedWarehouse.address.company_name,
                    ...this.selectedWarehouse.address,
                });
                const shipTo = addressToShipEngineFormat(
                    this.isForOrder ? this.order.shipping_address : this.customAddress,
                );
                const packages = this.packages.map(pkg => _.pick(pkg, ['weight', 'length', 'width', 'height']));

                class ZeroLengthRatesError extends Error {}

                try {
                    const { data: rates } = await getRates({
                        carrierId: this.selectedAdminCarrier.id,
                        shipTo,
                        shipFrom,
                        packages,
                    });
                    if (rates.length > 1) {
                        this.shippingRates = rates;
                    } else {
                        throw new ZeroLengthRatesError();
                    }
                } catch (error) {
                    if (error instanceof ZeroLengthRatesError) {
                        this.$alerts.danger('Shipping Rates are Unavailable', 'The shipping carrier could not get ' +
                            'rates for this shipment. This could be due to an error with your submission, or a ' +
                            'temporary outage. Please contact support if the problem persists.');
                    } else {
                        this.$alerts.danger('Error Getting Shipping Rates', 'Something went wrong when getting ' +
                            'rates for this shipment. Please contact support if the problem persists.');
                    }
                } finally {
                    this.isGettingRates = false;
                }

                if (this.shippingRates) {
                    // Scroll the rates list into view (uses jQuery)
                    $('html,body').animate({
                        scrollTop: $(this.$refs.shippingRatesForm).offset().top,
                    }, 500, 'swing');
                }
            },
            clearShippingRates() {
                this.shippingRates = null;
                this.selectedShippingRate = null;
            },
            async createShipment() {
                if (!this.runFormValidation(true, true, true, true)) {
                    return;
                }

                this.isCreatingShipment = true;

                const rate = this.selectedShippingRate;
                const carrier = this.selectedAdminCarrier;
                const packages = this.packages.map(pkg => ({
                    'weight': pkg.weight,
                    'length': pkg.length,
                    'width': pkg.width,
                    'height': pkg.height,
                    'items': pkg.items.map(item => ({
                        'product_variant_id': item.productVariant.id,
                        'barcode_data': { ...item.barcodeData },
                    })),
                }));

                try {
                    await createShipment({
                        shipEngineId: rate.shipment_id,
                        rateId: rate.rate_id,
                        orderId: this.order?.id,
                        carrierId: carrier.id,
                        packages,
                    });

                    if (this.isForOrder) {
                        this.$router.push({ name: 'order-details', params: { id: this.order.id }});
                    } else {
                        this.$router.push({ name: 'shipments' });
                    }
                } catch (error) {
                    this.isCreatingShipment = false;
                    this.$alerts.danger('Unable to Create Shipment', 'An error occurred while trying to create this ' +
                        'shipment. Please check your submission for errors. If the problem persists, contact support.');
                }
            },
            runFormValidation(addresses, packages, shippingCarrier, shippingRates) {
                let isValid = true;
                isValid &&= !addresses || this.$refs.addressesForm.reportValidity();
                isValid &&= !packages || this.$refs.packagesForm.reportValidity();
                isValid &&= !shippingCarrier || this.$refs.shippingCarrierForm.reportValidity();
                isValid &&= !shippingRates || this.$refs.shippingRatesForm.reportValidity();
                return isValid;
            },
        },
    };
</script>
