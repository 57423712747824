<template>
    <fieldset :id="id">
        <div class="form-row">
            <div class="col form-group">
                <label :for="`${id}-company-name`">Company Name</label>
                <input type="text"
                       :id="`${id}-company-name`"
                       class="form-control"
                       :value="value.company_name"
                       @input="update('company_name', $event.target.value)"
                       autocomplete="organization"
                       required>
            </div>
            <div class="col form-group">
                <label :for="`${id}-phone`">Phone Number <small>(Optional)</small></label>
                <input type="tel"
                       :id="`${id}-phone`"
                       class="form-control"
                       :value="value.phone"
                       @input="update('phone', $event.target.value)"
                       autocomplete="tel">
            </div>
        </div>
        <div class="form-group">
            <label :for="`${id}-address-line-1`">Address</label>
            <input type="text"
                   :id="`${id}-address-line-1`"
                   ref="addressLine1"
                   class="form-control"
                   :value="value.address_line_1"
                   @input="update('address_line_1', $event.target.value)"
                   autocomplete="address-line1"
                   placeholder=""
                   required>
        </div>
        <div class="form-group">
            <label :for="`${id}-address-line-2`">Apartment, Suite, etc. <small>(Optional)</small></label>
            <input type="text"
                   :id="`${id}-address-line-2`"
                   ref="addressLine2"
                   class="form-control"
                   :value="value.address_line_2"
                   autocomplete="address-line2"
                   @input="update('address_line_2', $event.target.value)">
        </div>
        <div class="form-row">
            <div class="col form-group">
                <label :for="`${id}-city`">City</label>
                <input type="text"
                       :id="`${id}-city`"
                       class="form-control"
                       :value="value.city"
                       @input="update('city', $event.target.value)"
                       autocomplete="address-level2"
                       required>
            </div>
            <div class="col form-group">
                <label :for="`${id}-state-province`">State</label>
                <select class="custom-select"
                        :id="`${id}-state-province`"
                        :value="value.state_province_code"
                        @input="update('state_province_code', $event.target.value)"
                        autocomplete="address-level1"
                        required>
                    <option v-if="!value.state_province_code" value="" disabled />
                    <option v-for="state in states" :key="state.code" :value="state.code">
                        {{ state.name }}
                    </option>
                </select>
            </div>
        </div>
        <div class="form-row">
            <div class="col form-group">
                <label :for="`${id}-country`">Country</label>
                <input type="text"
                       :id="`${id}-country`"
                       class="form-control"
                       value="United States"
                       disabled>
            </div>
            <div class="col form-group">
                <label :for="`${id}-postal-code`">Postal Code</label>
                <input type="text"
                       :id="`${id}-postal-code`"
                       class="form-control"
                       :value="value.postal_code"
                       @input="update('postal_code', $event.target.value)"
                       autocomplete="postal-code"
                       required>
            </div>
        </div>
    </fieldset>
</template>

<script>
    import { usStates } from '../../data';

    const Autocomplete = window.google.maps.places.Autocomplete;

    export default {
        props: {
            id: { type: String, required: true },
            value: { type: Object, required: true },
        },
        data: () => ({
            states: usStates.sort((a, b) => a.name.localeCompare(b.name)),
        }),
        mounted() {
            const input = this.$refs.addressLine1;
            // https://developers.google.com/maps/documentation/javascript/reference/places-widget#AutocompleteOptions
            const options = {
                componentRestrictions: { country: 'us' },
                fields: ['address_components'],
            };
            this.autocomplete = new Autocomplete(input, options);
            this.autocomplete.addListener('place_changed', this.autocompleteAddressSelected);
        },
        methods: {
            update(key, value) {
                // https://github.com/vuejs/vue/issues/4373#issuecomment-279826554
                this.$emit('input', { ...this.value, [key]: value });
            },
            autocompleteAddressSelected() {
                // This code is somewhat similar to the Google Places example:
                // https://developers.google.com/maps/documentation/javascript/places-autocomplete#get-place-information

                const selectedAddress = { ...this.value };  // Make a copy of the form value

                // These variables are a combination of two address components
                let address_line_1 = '';  // street_number + route
                let postal_code = '';  // postal_code + postal_code_suffix

                for (const component of this.autocomplete.getPlace().address_components) {
                    const componentType = component.types[0];

                    switch (componentType) {
                    case 'street_number':
                        address_line_1 = `${component.long_name} ${address_line_1}`;
                        break;
                    case 'route':
                        address_line_1 += component.long_name;
                        break;
                    case 'postal_code':
                        postal_code = `${component.long_name}${postal_code}`;
                        break;
                    case 'postal_code_suffix':
                        postal_code = `${postal_code}-${component.long_name}`;
                        break;
                    case 'locality':
                        selectedAddress.city = component.long_name;
                        break;
                    case 'administrative_area_level_1':
                        selectedAddress.state_province_code = component.short_name;
                        break;
                    }
                }
                selectedAddress.address_line_1 = address_line_1;
                selectedAddress.address_line_2 = '';
                selectedAddress.postal_code = postal_code;

                this.$emit('input', selectedAddress);
                this.$refs.addressLine2.focus();
            },
        },
    };
</script>
