<template>
    <div>
        <h6 v-if="address.company_name" class="mb-2">{{ address.company_name }}</h6>
        <p class="mb-1 fs--1">
            {{ address.address_line_1 }}
            <template v-if="address.address_line_2">
                <br>{{ address.address_line_2 }}
            </template>
            <br>{{ address.city }}, {{ address.state_province_code }}
            {{ address.postal_code }} {{ address.country_iso_code }}
        </p>
        <p v-if="address.phone" class="mb-0 fs--1">
            <strong>Phone:</strong> <a :href="`tel:${address.phone}`">{{ address.phone }}</a>
        </p>
    </div>
</template>

<script>
    export default {
        props: {
            address: { type: Object, required: true },
        },
    };
</script>
