<template>
    <div>
        <!-- These need to be wrapped in separate <div>s to avoid duplicate :key values -->
        <div>
            <Product v-for="product in orderProducts"
                     :key="product.productVariantId"
                     :product="product"
                     :completed-quantity="Math.min(product.quantityShipped + product.quantityScanned, product.quantityOrdered)"
                     :ordered-quantity="product.quantityOrdered"
                     class="mb-2" />
        </div>
        <div v-if="extraProducts.length > 0">
            <h6 class="mt-3">Extra Items</h6>
            <Product v-for="product in extraProducts"
                     :key="product.productVariantId"
                     :product="product"
                     :completed-quantity="product.quantityShipped + product.quantityScanned - product.quantityOrdered"
                     class="mb-2" />
        </div>
    </div>
</template>

<script>
    import Product from './Product.vue';

    export default {
        components: { Product },
        props: {
            bcProducts: { type: Array, required: true },
            orderProductVariants: { type: Array, required: true },
            scannedItems: { type: Array, required: true },
        },
        computed: {
            /** Normalized representation of all product variants that are part of this order, with counts */
            productVariants: vm => {
                const productVariants = new Map();

                vm.bcProducts.forEach((product) => {
                    const productVariantId = product.variant_id;
                    productVariants.set(productVariantId, {
                        productVariantId: product.variant_id,
                        name: product.name,
                        sku: product.sku,
                        imageUrl: product.image_url,
                        inStock: product.is_in_stock,
                        quantityOrdered: product.quantity,
                        quantityShipped: 0,
                        quantityScanned: 0,
                    });
                });

                vm.orderProductVariants.forEach((productVariant) => {
                    const productVariantId = productVariant.bigcommerce_product_variant_id;
                    if (productVariants.has(productVariantId)) {
                        productVariants.get(productVariantId).quantityShipped = productVariant.quantity_shipped;
                    } else {
                        productVariants.set(productVariantId, {
                            productVariantId: productVariant.bigcommerce_product_variant_id,
                            name: productVariant.product_name,
                            sku: productVariant.name,
                            imageUrl: productVariant.image_url,
                            inStock: productVariant.is_in_stock,
                            quantityOrdered: 0,
                            quantityShipped: productVariant.quantity_shipped,
                            quantityScanned: 0,
                        });
                    }
                });

                vm.scannedItems.forEach((item) => {
                    const productVariantId = item.productVariant.bigcommerce_product_variant_id;
                    if (productVariants.has(productVariantId)) {
                        productVariants.get(productVariantId).quantityScanned += 1;
                    } else {
                        productVariants.set(productVariantId, {
                            productVariantId: item.productVariant.bigcommerce_product_variant_id,
                            name: item.productVariant.product_name,
                            sku: item.productVariant.name,
                            imageUrl: item.productVariant.image_url,
                            inStock: item.productVariant.is_in_stock,
                            quantityOrdered: 0,
                            quantityShipped: item.productVariant.quantity_shipped,
                            quantityScanned: 1,
                        });
                    }
                });

                return Array.from(productVariants.values());
            },
            /** Products that are a part of the order */
            orderProducts: vm => vm.productVariants.filter(pv => pv.quantityOrdered > 0),
            /** Products that are not part of the order or are over-fulfilled */
            extraProducts: vm => (
                vm.productVariants.filter(pv => pv.quantityShipped + pv.quantityScanned > (pv.quantityOrdered || 0))
            ),
        },
    };
</script>
