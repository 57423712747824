<template>
    <div class="position-relative bg-200 text-900 rounded p-3">
        <div class="line-height-1 mb-1 mr-4">
            <strong>{{ item.productVariant.product_name }}</strong> <small>{{ item.productVariant.name }}</small>
        </div>
        <ul class="list-unstyled fs--2 mb-0">
            <li v-for="(data, ai) in item.barcodeData" :key="ai">
                <strong>
                    {{ gs1CommonElements[ai] || ai }}:
                </strong>
                {{ data | formatGs1Value(ai) }}
            </li>
        </ul>
        <button class="btn btn-close position-absolute t-0 r-0" @click="$emit('remove-item')">
            <i class="fa fa-times" />
        </button>
    </div>
</template>

<script>
    import { gs1CommonElements, gs1DateElements } from '../../util/gs1';
    import { formatDate } from '../../filters';

    export default {
        filters: {
            formatGs1Value(data, ai) {
                return gs1DateElements.includes(ai) ? formatDate(data, 'yyMMdd', 'PP') : data;
            },
        },
        props: {
            item: { type: Object, required: true },
        },
        created() {
            // Non-reactive constant for the template
            this.gs1CommonElements = gs1CommonElements;
        },
    };
</script>
