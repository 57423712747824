<template>
    <ul class="list-group">
        <li v-for="(rate, index) in rates"
            :key="rate.rate_id"
            class="list-group-item list-group-item-action cursor-pointer d-flex justify-content-between align-items-center"
            @click.self="$event.target.querySelector('input[type=radio]').click()">
            <div class="custom-control custom-radio">
                <input type="radio"
                       :value="rate"
                       v-model="valueProxy"
                       class="custom-control-input"
                       :id="`${id}-rate-${index}`"
                       :name="`${id}-rates`"
                       required>
                <label class="custom-control-label cursor-pointer" :for="`${id}-rate-${index}`">
                    {{ rate.service_type }}
                </label>
            </div>
            <span class="badge badge-soft-primary badge-pill">
                {{ rate.shipping_amount.amount | formatDollars }}
            </span>
        </li>
    </ul>
</template>

<script>
    export default {
        props: {
            id: { type: String, default: '' },
            rates: { type: Array, required: true },
            value: { type: null, required: true },
        },
        computed: {
            valueProxy: {
                get() {
                    return this.value;
                },
                set(newVal) {
                    this.$emit('input', newVal);
                },
            },
        },
    };
</script>
