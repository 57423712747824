<template>
    <div class="cursor-pointer" @click="focusInput">
        <div class="text-600 bg-300 rounded d-flex align-items-center justify-content-center position-relative"
             style="width: 5rem; height: 5rem;">
            <i v-if="!isScanning" class="fas fa-scanner fs-4" />
            <div v-else class="spinner-border" role="status" />
            <div class="position-absolute a-0">
                <input type="text"
                       ref="scanInput"
                       v-model="scanBuffer"
                       class="w-100 h-100 hidden-input"
                       @focus="isScanning = true"
                       @blur="isScanning = false"
                       @keydown.esc.prevent="isScanning = false"
                       @keydown.enter.prevent="handleScan">
            </div>
        </div>
        <div class="fs--2 font-weight-semi-bold text-center mt-1 user-select-none no-intrinsic-size">
            <template v-if="!isScanning">Click to scan</template>
            <template v-else>Ready&hellip;</template>
        </div>
    </div>
</template>

<script>
    import { parseGS1 } from '../util';

    export default {
        name: 'GS1ScanField',
        props: {
            scanNeeded: { type: Boolean },
        },
        data() {
            return {
                isScanning: false,
                scanBuffer: '',
            };
        },
        watch: {
            scanNeeded: 'setInputValidity',
        },
        mounted() {
            this.setInputValidity();
        },
        methods: {
            /** Focuses the scan input and clears its content */
            focusInput() {
                this.scanBuffer = '';
                this.$refs.scanInput.focus();
            },
            /** Parses the provided barcode and emits the barcode's data */
            handleScan() {
                try {
                    if (!this.scanBuffer) {
                        throw Error('No scanned data');
                    }

                    const parsed = parseGS1(this.scanBuffer, { pre: '+', post: '+' });
                    this.$emit('scan-parse', parsed);
                } catch (error) {
                    this.$emit('scan-error', error);
                } finally {
                    this.focusInput();
                }
            },
            /** Sets validity on the input based on the `scanNeeded` prop */
            setInputValidity() {
                this.$refs.scanInput.setCustomValidity(this.scanNeeded ? 'Please add an item to this package.' : '');
            },
        },
    };
</script>

<style scoped>
    .hidden-input {
        opacity: 0;
        overflow: hidden;
        pointer-events: none;
    }

    /* This ensures that this element will never cause the parent to grow */
    .no-intrinsic-size {
        width: 0;
        min-width: 100%;
    }
</style>
