<template>
    <div>
        <div v-for="(carrier, index) in carriers" :key="carrier.id">
            <div class="custom-control custom-radio">
                <input type="radio"
                       :value="carrier"
                       v-model="valueProxy"
                       class="custom-control-input"
                       :id="`${id}-carrier-${index}`"
                       :name="`${id}-carrier`"
                       required>
                <label :for="`${id}-carrier-${index}`"
                       class="custom-control-label">
                    {{ carrier.name }}
                </label>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            carriers: { type: Array, required: true },
            id: { type: String, default: '' },
            value: { type: null, required: true },
        },
        computed: {
            valueProxy: {
                get() {
                    return this.value;
                },
                set(newVal) {
                    this.$emit('input', newVal);
                },
            },
        },
    };
</script>
