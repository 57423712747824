<template>
    <div v-if="amount === total" class="progress" style="height: 8px;">
        <div class="progress-bar bg-success"
             style="width: 100%;"
             aria-valuenow="100"
             aria-valuemin="0"
             aria-valuemax="100"
             role="progressbar" />
    </div>
    <div v-else-if="amount < total" class="progress" style="height: 8px;">
        <div class="progress-bar bg-info"
             :style="{ 'width': `${(100 / total) * amount}%` }"
             :aria-valuenow="`${(100 / total) * amount}`"
             aria-valuemin="0"
             aria-valuemax="100"
             role="progressbar" />
    </div>
    <div v-else-if="amount > total" class="progress" style="height: 8px;">
        <div class="progress-bar bg-success"
             :style="{ 'width': `${100 - (amount * total)}%` }"
             :aria-valuenow="`${100 - (amount * total)}`"
             aria-valuemin="0"
             aria-valuemax="100"
             role="progressbar" />
        <div class="progress-bar"
             :style="{ 'width': `${amount * total}%` }"
             :aria-valuenow="`${amount * total}`"
             aria-valuemin="0"
             aria-valuemax="100"
             role="progressbar" />
    </div>
</template>
<script>
    export default {
        name: 'ProgressBar',
        props: {
            amount: { type: Number, required: true },
            total: { type: Number, required: true },
        },
    };
</script>
